// DEFAULT
@import '../general/reset';
@import '../general/media-screen';
@import '../general/variable';
@import '../general/color';
@import '../general/fonts';
@import '../general/mixins';
@import '../general/common';
//libs
@import '../libs/animations';
//component
@import '../components/infinity_text';
@import '../components/section_blog';

#business {
    .servitec {
        position: relative;
        isolation: isolate;
        background-color: #1E1E1E;
        padding-bottom: 161px;

        display: flex;
        justify-content: end;

        @media (max-width: 1100px) {
            padding-bottom: 0;

            flex-direction: column;
            justify-content: start;
            align-items: end;
        }
        &--floating {
            position: relative;
            background-color: #fff;
            padding: 120px 70px 152px 200px;
            width: Min(1249px, 75vw);

            @media (max-width: 1100px) {
                width: 100%;
                padding: 44px 20px 149px 52px;
            }
            .content {
                max-width: 662px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                }
                .title {
                    line-height: 70px;
                    margin-bottom: 27px;

                    @media (max-width: 1100px) {
                        line-height: inherit;
                        margin-bottom: 20px;
                        max-width: 318px;
                    }
                }
                p {
                    max-width: 537px;

                    @media (max-width: 1100px) {
                        max-width: 100%;
                    }
                }
            }
            .shape {
                bottom: 152px;
                left: 80px;

                @media (max-width: 1100px) {
                    height: 280px;
                    left: 16px;
                    bottom: 43px;
                }
            }
        }
        .review_img {
            position: absolute;
            left: 0;
            top: 149px;
            z-index: -3;

            width: Min(998px, 52vw);
            height: 702px;

            @media (max-width: 1100px) {
                position: unset;
                z-index: 1;
                margin-top: -122px;

                width: Min(100%, 89vw);
                height: 234px;
                object-fit: cover;

                .image-wrap { position: initial; }
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        // .bg-img {
        //     width: Min(998px, 52vw);
        //     height: 702px;

        //     left: 0;
        //     top: 149px;

        //     @media (max-width: 1100px) {
        //         position: unset;
        //         z-index: 1;
        //         margin-top: -122px;

        //         width: Min(100%, 89vw);
        //         height: 234px;
        //         object-fit: cover;
        //     }
        // }
        &::after {
            content: '';
            position: absolute;
            background-color: #fff;
            inset: 0 0 60% 0;
            z-index: -4;

            @media (max-width: 1100px) {
                display: none;
            }
        }
    }
    .timeline {
        position: relative;
        background-color: #1E1E1E;
        padding: 0 0 90px;

        @media(max-width: 1500px) {
            padding: 0 70px 160px;
        }
        @media (max-width: 1100px) {
            padding: 44px 0 60px;
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 55px;
            max-width: 1527px;
            margin-inline-start: auto;

            @media (max-width: 1400px) {
                gap: 35px;
            }
            @media (max-width: 1100px) {
                flex-direction: column;
                justify-content: start;
                gap: 44px;
            }
        }
        &--text {
            width: 503px;
            align-self: end;
            padding-top: 20px;

            @media (max-width: 1400px) {
                width: 340px;
            }
            @media (max-width: 1100px) {
                width: 100%;
                align-self: start;
            }
            .title {
                margin-bottom: 27px;

                @media (max-width: 1400px) {
                    font-size: 38px;
                    line-height: 43px;
                }
                @media (max-width: 1100px) {
                    margin-bottom: 20px;
                }
            }
            p { color: #fff; }
        }
        &--year {
            align-self: end;
            margin-bottom: 131px;

            @media (max-width: 1100px) {
                align-self: center;
                margin-bottom: 0;
            }
            .wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;

                @media (max-width: 1100px) {
                    flex-direction: row;
                }
                .year {
                    color: #FAD224;
                    font-family: "Rex Bold", sans-serif;
                    font-size: 70px;
                    font-weight: 700;
                    line-height: 60px;
                    letter-spacing: -0.02em;
                    text-align: center;

                    @media (max-width: 1400px) {
                        font-size: 45px;
                    }
                }
                .arrow {
                    width: 32px;
                    height: 32px;
                    display: flex !important;
                    transition: all .3s ease-in-out;

                    &.arrow-up {
                        rotate: 180deg;

                        &:hover {
                            translate: 0 -3px;

                            @media (max-width: 1100px) {
                                translate: -3px 0;
                            }
                        }
                        @media (max-width: 1100px) {
                            rotate: 90deg;
                        }
                    }
                    &.arrow-down {
                        &:hover {
                            translate: 0 3px;

                            @media (max-width: 1100px) {
                                translate: 3px 0;
                            }
                        }
                        @media (max-width: 1100px) {
                            rotate: -90deg;
                        }
                    }
                }
            }
        }
        &--img {
            width: min(624px, 41%);
            height: 421px;
            position: relative;

            @media (max-width: 1100px) {
                width: 96%;
                height: 238px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .floating-text {
                position: absolute;
                bottom: 50px;
                right: -43px;

                color: #fff;
                font-family: 'Rex Bold', sans-serif;
                font-size: 100px;
                font-weight: 700;
                line-height: 65px;
                letter-spacing: 0.03em;
                writing-mode: vertical-rl;

                @media (max-width: 1100px) {
                    bottom: 32px;
                    right: -25px;

                    font-size: 56.6px;
                    line-height: 36.79px;
                }
            }
        }
        .swiper {
            max-width: 100%;
            height: fit-content;

            @media (max-width: 1100px) {
                max-height: 100%;
            }
            &-slide {
                padding-inline: 143px;
                opacity: 0 !important;

                @media (max-width: 1100px) {

                    padding-inline: 20px;
                }
                &.swiper-slide-active {
                    opacity: 1 !important;
                }
            }
            &-pagination {
                position: unset;
                margin-top: 70px;
                display: flex;
                justify-content: center;
                flex-wrap: nowrap;

                @media (max-width: 1200px) {
                    overflow-x: scroll;
                    justify-content: start;
                    padding-inline: 20px;
                }
                &-bullet {
                    // reset styles
                    width: fit-content;
                    height: fit-content;
                    border-radius: 0;
                    background: transparent;
                    opacity: 1 !important;

                    margin: 0 10px;
    
                    // font style
                    color: #fff;
                    font-family: "Rex Bold", sans-serif;
                    font-size: 30px;
                    font-weight: 700;
                    letter-spacing: -0.02em;

                    &-active {
                        color: #FAD224 !important;
                    }
                }
            }
        }
    }
    .infinite {
        margin-block: 60px 120px;

        @media (max-width: 1100px) {
            margin-block: 44px 60px;
        }
    }
    .values {
        padding: 0 70px 165px;
        position: relative;

        @media (max-width: 1100px) {
            padding: 0 20px 141px;
        }
        &--text {
            margin-bottom: 96px;

            @media (max-width: 1100px) {
                margin-bottom: 60px;
            }
            .content {
                max-width: 558px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                    text-align: center;
                }
            }
            .text-content {
                max-width: 544px;
                padding-top: 22px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                    padding-top: 32px;
                }
                p {
                    text-align: end;

                    @media (max-width: 1100px) {
                        text-align: center;
                    }
                }
            }
        }
        &--cards {
            .cards-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                @media (max-width: 1100px) {
                    grid-template-columns: 1fr;
                }
                .card {
                    padding: 60px 40px;
                    border: 1px solid #D9D9D9;
                    border-radius: 0;
                    position: relative;
                    z-index: 3;

                    @media (max-width: 1100px) {
                        padding: 44px 32px;
                    }
                    &-icon {
                        width: 63px;
                        height: 60px;
                        background-color: #242424;

                        display: grid;
                        place-items: center;

                        @media (max-width: 1100px) {
                            width: 42px;
                            height: 40px;
                        }
                        svg, img {
                            width: 32px;
                            height: 32px;

                            @media (max-width: 1100px) {
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                    &-title {
                        color: #242424;
                        font-family: 'Raleway', sans-serif;
                        font-size: 35px;
                        font-weight: 700;
                        line-height: 50px;
                        margin-block: 20px 40px;

                        @media (max-width: 1100px) {
                            font-size: 24px;
                            line-height: 34px;
                            margin-block: 20px;
                        }
                    }
                    p, li {
                        color: #5A5D67;
                        font-family: 'Raleway', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 25px;

                        @media (max-width: 1100px) {
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }
                    ul {
                        margin: 0;

                        li {
                            line-height: 36px;

                            @media (max-width: 1100px) {
                                line-height: 26px;
                            }
                        }
                    }
                    // grid layout
                    &:nth-child(3) {
                        grid-row: span 2;
                        height: fit-content;

                        @media (max-width: 1100px) {
                            grid-row: auto;
                            height: auto;
                        }
                    }
                    &:nth-child(4) {
                        grid-column: span 2;

                        @media (max-width: 1100px) {
                            grid-column: auto;
                        }
                    }
                }
            }
        }
        .shape {
            height: 422px;

            right: 435px;
            bottom: -30px;
            z-index: 2;

            @media (max-width: 1100px) {
                height: 186px;
                right: 67px;
                bottom: -79px;
            }
        }
    }
    .testimonials {
        position: relative;

        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column-reverse;
            gap: 44px;
        }
        // bg color
        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background-color: #f5f5f5;
            width: Min(1698px, 90vw);
            z-index: -1;

            @media (max-width: 1100px) {
                width: 100%;
                inset: 109px 0 0 0;
            }
        }
        &--text {
            padding-block: 120px;

            @media (max-width: 1100px) {
                padding-block: 0 60px;

                & > .container {
                    padding-inline: 20px;
                    margin-inline: 0;
                    max-width: 100%;
                }
            }
            .content {
                max-width: 434px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                }
                p {
                    margin-block: 30px;
                }
            }
        }
        &--slider {
            position: absolute;
            inset: -68px 0 auto auto;
            z-index: 1;

            width: Min(1045px, 60vw);
            min-height: 518px;

            @media (max-width: 1400px) {
                width: Min(1045px, 50vw);
            }
            @media (max-width: 1100px) {
                position: unset;
                width: 100%;
                padding-left: 20px;
                min-height: auto;
            }
            .testimonials-sl {
                width: 100%;
                height: 100%;
            }
        }
        &--item {
            width: 100%;
            height: 100%;
            min-height: 518px;
            background-color: #1E1E1E;
            padding: 156px 154px 120px 157px;

            @media (max-width: 1400px) {
                padding-inline: 50px;
            }
            @media (max-width: 1100px) {
                padding: 40px 26px;
                min-height: auto;
            }
            .title-sm {
                color: #fff;
                font-family: 'Plus Jakarta Sans', sans-serif;
                font-size: 35px;
                font-weight: 500;
                line-height: 50px;
                letter-spacing: -0.02em;
                margin-bottom: 8px;

                @media (max-width: 1100px) {
                    font-size: 24px;
                    line-height: 34px;
                }
            }
            .sub-title-sm {
                color: #fff;
                font-family: 'Raleway', sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: -0.02em;
                opacity: .8;

                @media (max-width: 1100px) {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    font-size: 14px;
                    line-height: 26px;
                }
                &:empty {
                    height: 21px;
                }
                img {
                    display: none;

                    @media (max-width: 1100px) {
                        display: flex;
                        width: 40px;
                        aspect-ratio: 1;
                        border-radius: 50%;
                    }
                }
            }
            .wrapper {
                display: flex;
                gap: 32px;
                margin-top: 12px;

                @media (max-width: 1100px) {
                    display: block;
                    margin-top: 20px;
                }
                &--img {
                    @media (max-width: 1100px) {
                        display: none;
                    }
                    .img-wrapper {
                        width: 141px;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                &--quote {
                    p {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 36px;

                        @media (max-width: 1100px) {
                            font-size: 14px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
    .gallery {
        margin: 120px 100px 96px;
        position: relative;
        @media (max-width: 1100px) {
            margin: 60px 0 33px;
        }
        &-floating {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;

            padding: 72px 23px 76px;
            width: 31.5vw;

            @media (max-width: 1100px) {
                position: unset;
                width: 100%;
                padding: 0 20px 44px;
            }
            .wrapper {
                max-width: 411px;
                margin-left: auto;

                @media (max-width: 1100px) {
                    max-width: 100%;

                    display: flex;
                    justify-content: space-between;
                }
                .title {
                    @media (max-width: 1100px) {
                        max-width: 250px;
                        margin-top: 24px;
                    }
                }
                .arrows-container {
                    margin-top: 78px;

                    @media (max-width: 1100px) {
                        margin-top: 0;
                        align-self: flex-end;
                    }
                }
            }
        }
        &-imgs {
            @media (max-width: 1100px) {
                overflow: hidden;
            }
            .imgs-container {
                max-width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                @media (max-width: 1100px) {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 12px 6px;
                }
                .item {
                    overflow: hidden;
                    width: 100%;

                    @media (max-width: 1100px) {
                        width: 100%;

                        &:nth-child(1) { display: none; }
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        translate: 50px 0;

                        @media (max-width: 1100px) {
                            translate: none;
                        }
                    }
                    &:nth-child(4) {
                        width: 606px;

                        @media (max-width: 1500px) {
                            width: fit-content;
                        }
                        @media (max-width: 1100px) {
                            width: 100%;
                        }
                    }
                    img {
                        max-width: 100%;
                        height: 100%;
                        min-height: 365px;
                        object-fit: cover;
                        transition: all .3s ease-in-out;
                        height: 426px;
                        width: 100%;
                        @media (max-width: 1100px) {
                            min-height: 146px;
                            max-height: 146px;
                            width: 100%;
                        }
                    }
                }
                .swiper {
                    height: 100%;

                    &-slide-active {
                        &:hover {
                            img {
                                cursor: zoom-in;
                                scale: 1.1;
                            }
                        }
                    }
                }
            }
        }
        .shape {
            bottom: -404px;
            right: 236px;
            z-index: -1;

            height: 722px;

            @media (max-width: 1100px) {
                display: none;
            }
        }
    }
    .video {
        margin: 0 100px 138px;
        position: relative;

        @media (max-width: 1100px) {
            margin: 0 0 60px;
            overflow: hidden;
        }
        &-wrapper {
            display: flex;
            gap: 50px;

            @media (max-width: 1100px) {
                flex-direction: column-reverse;
                gap: 81px;
            }
        }
        &--thumbs {
            .swiper {
                width: 852px;

                @media (max-width: 1100px) {
                    width: 100%;
                    max-width: 100%;
                }
                &.mySwiper2 {
                    height: 450px;
                    margin-bottom: 20px;

                    @media (max-width: 1100px) {
                        height: 225px;
                    }
                    .swiper-slide {
                        @media (max-width: 1100px) {
                            padding-inline: 20px;
                        }
                        .play {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            translate: -50% -50%;

                            width: 100px;
                            aspect-ratio: 1;
                            transition: all .3s ease-in-out;

                            @media (max-width: 1100px) {
                                width: 44px;
                            }
                            &:hover {
                                scale: 1.1;
                            }
                        }
                    }
                }
                &.mySwiper {
                    height: 76.3px;

                    @media (max-width: 1100px) {
                        height: 42.38px;
                        margin-left: 20px;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    .swiper-slide {
                        @media (max-width: 1100px) {
                            max-width: 60px;
                        }
                        &-thumb-active {
                            border: 4px solid #FAD224;

                            @media (max-width: 1100px) {
                                border: 2px solid #FAD224;
                            }
                        }
                    }
                }
                &-slide {
                    position: relative;

                    img:not(.play) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &-button-next,
                &-button-prev {
                    &::after { display: none; }
                }
            }
        }
        &--text {
            padding-top: 21px;
            flex-grow: 1;
            max-width: 460px;

            display: flex;
            justify-content: end;

            @media (max-width: 1100px) {
                padding-top: 0;
                max-width: 100%;
                padding-inline: 20px;
            }
            .content {
                max-width: 305px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                    width: 100%;

                    display: flex;
                    gap: 20px;
                }
                .title {
                    @media (max-width: 1100px) {
                        max-width: 194px;
                    }
                }
                .arrows-container {
                    margin-top: 78px;

                    @media (max-width: 1100px) {
                        margin-top: 0;
                        align-self: flex-end;
                    }
                }
            }
        }
    }
}

// re-usable sections & utilitary classes
.inner-banner {
    height: Min(100vh, 522px);
    position: relative;
    isolation: isolate;

    @media (max-width: 1100px) {
        height: Min(100vh, 410px);

        .fixed-logo { display: none; }
    }
    &-container {
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 50px 100px 80px;

        @media (max-width: 1100px) {
            padding: 0 20px 40.2px;
        }
        .content {
            max-width: 1085px;

            @media (max-width: 1100px) {
                display: flex;
                flex-direction: column-reverse;
                gap: 32px;
            }
            .breadcumbs {
                ul {
                    display: flex;
                    margin-bottom: Min(62px, 10vh);
                    color: #fff;

                    @media (max-width: 1100px) {
                        margin-bottom: 0;
                    }
                    &, li a {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                    }

                    li {
                        &:not(:last-of-type) {
                            a { opacity: .8; }
                        }
                        &:hover {
                            a { opacity: 1; }
                        }
                        a {
                            font-family: 'Raleway', sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 30px;
                            text-decoration: none;
                            transition: all .3s ease-in-out;

                            @media (max-width: 1100px) {
                                line-height: 18.78px;
                            }
                            svg {
                                rotate: -90deg;
                            }
                        }
                    }
                }
            }
            &--main {
                .main-title {
                    color: #fff;
                    font-family: "Plus Jakarta Sans", sans-serif;
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 48px;
                    letter-spacing: -0.02em;

                    @media (max-width: 1100px) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    strong {
                        font-family: inherit;
                        font-weight: inherit;
                        color: #FAD224;
                    }
                }
            }
        }
    }
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background:
            linear-gradient(180deg, #3F3E38 0%, rgba(10, 10, 10, 0) 100%),
            linear-gradient(182.01deg, rgba(0, 0, 0, 0) 9.37%, #000000 98.3%),
            linear-gradient(180deg, rgba(0, 0, 0, 0.08) 10.69%, #000000 82%);
        mix-blend-mode: multiply;
        opacity: .7;
        z-index: -2;
    }
}
.bg-img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
}
.link { // hover underline
    position: relative;

    &::before{
        content: '';
        position: absolute;
        width: 0%;
        height: 1px;
        background: #FAD224;
        bottom: -4.67px;
        left: 0;
        transition: all  .3s ease-in-out;
    }
    &:hover{
        &::before{
            width: 100%;
        }
    }
}
.title {
    color: #242424;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 65px;
    font-weight: 500;
    line-height: 65px;
    letter-spacing: -0.02em;

    @media (max-width: 1100px) {
        font-size: 40px;
        line-height: 44px;
    }
    strong {
        color: #FAD224;
        font-family: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
}
.sub-title {
    color: #939393;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 46px;

    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 24px;
    }
}
.t-light {
    color: #fff !important;
}
p {
    color: #939393;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;

    @media (max-width: 1100px) {
        font-size: 14px;
        line-height: 32px;
    }
}
.main-btn {
    @include fontTemplate(1.6rem, 1, $black500, $bold, $rex, start);
    text-decoration: none;
    background: $yellow;
    height: 46px;
    padding-inline: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    transition: $transition;
    text-transform: uppercase;

    &:hover{
        color: $yellow;
        background: $black500;
    }
}
.arrows-container { // slider arrows container
    display: flex;
    align-items: center;
    gap: 20px;

    button {
        svg {
            width: 32px;
            height: 32px;

            &:hover {
                path { fill: #FAD224; }
            }
            path {
                fill: #242424;
                transition: all .3s ease-in-out;
            }
        }
    }
    .prev,
    .prev-arrow,
    .prev-arrow-vids {
        svg {
            rotate: 90deg;
        }
    }
    .next,
    .next-arrow,
    .next-arrow-vids {
        svg {
            rotate: -90deg;
        }
    }
}
.shape { // yellow floating line
    position: absolute;

    background-color: #FAD224;
    width: 10px;
    height: 278px;

    @media (max-width: 1100px) {
        width: 6px;
    }
}
#whatsapp {
    position: fixed;
    right: 61px;
    bottom: 80px;
    transition: all .3s ease-in-out;
    z-index: -1;
    opacity: 0;

    @media (max-width: 1100px) {
        bottom: 20px;
        right: 20px;
    }
    &:hover {
        img, svg {
            translate: 0 -5px;
        }
    }

    img, svg {
        width: 80px;
        aspect-ratio: 1;
        transition: all .3s ease-in-out;

        @media (max-width: 1100px) {
            width: 60px;
        }
    }
}
